import React from "react"
import CategoryReport from "../components/Report/categoryReport"
import Layout from "../components/Base/Layout"

export default function categoryReportTemplate({ pageContext }) {
  return (
    <Layout>
      <CategoryReport slug={pageContext.slug} />
    </Layout>
  )
}
